<template>
  <div
    id="app"
    :class="{ 'has-mouse': hasMouse }"
    @touchstart="hasMouse = false"
  >
    <Flipbook
      class="flipbook"
      :pages="pages"
      :pagesHiRes="pagesHiRes"
      :startPage="pageNum"
      :zooms="zooms"
      v-slot="flipbook"
      ref="flipbook"
      @flip-left-start="onFlipLeftStart"
      @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart"
      @flip-right-end="onFlipRightEnd"
      @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd"
      :ambient="0.2"
      :gloss="0.5"
      :nPolygons="20"
    >
      <div class="action-bar">
        <left-icon
          class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft"
        />
        <plus-icon
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn"
        />
        <span class="page-num">
          Page {{ flipbook.page }} of {{ flipbook.numPages }}
        </span>
        <minus-icon
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut"
        />
        <right-icon
          class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight"
        />
        <select class="language-switcher" v-model="currentLanguage" @change="changeLanguage">
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="latam">Español (Americas)</option>
          <option value="pl">Polski</option>
          <option value="pt">Português</option>
          <option value="tr">Türkçe</option>
        </select>
      </div>
    </Flipbook>
  </div>
</template>

<script>
import 'vue-material-design-icons/styles.css'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
import Flipbook from 'flipbook-vue'

export default {
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon },
  data() {
    return {
      pages: [],
      pagesHiRes: [],
      hasMouse: true,
      pageNum: null,
      currentLanguage: 'en',
      zooms: [1, 2]
    }
  },
  methods: {
    onFlipLeftStart(page) { console.log('flip-left-start', page) },
    onFlipLeftEnd(page) {
      console.log('flip-left-end', page)
      window.location.hash = '#' + page
      this.updateCanFlipClasses()
    },
    onFlipRightStart(page) { console.log('flip-right-start', page) },
    onFlipRightEnd(page) {
      console.log('flip-right-end', page)
      window.location.hash = '#' + page
      this.updateCanFlipClasses()
    },
    onZoomStart(zoom) {
      console.log('zoom-start', zoom)
    },
    onZoomEnd(zoom) {
      console.log('zoom-end', zoom)
    },
    setPageFromHash() {
      const n = parseInt(window.location.hash.slice(1), 10)
      if (isFinite(n)) {
        this.pageNum = n
        this.updateCanFlipClasses()
      }
    },
    changeLanguage() {
      this.loadPages(this.currentLanguage)
    },
    loadPages(lang) {
      this.pages = [
        null,
        `images/${lang}/cover.png`,
        `images/${lang}/katie1.png`,
        `images/${lang}/katie2.png`,
        `images/${lang}/emily1.png`,
        `images/${lang}/emily2.png`,
        `images/${lang}/glen1.png`,
        `images/${lang}/glen2.png`,
        `images/${lang}/jose1.png`,
        `images/${lang}/jose2.png`,
        `images/${lang}/kamila1.png`,
        `images/${lang}/kamila2.png`,
      ]
      this.pagesHiRes = [
        null,
        `images-large/${lang}/cover.png`,
        `images-large/${lang}/katie1.png`,
        `images-large/${lang}/katie2.png`,
        `images-large/${lang}/emily1.png`,
        `images-large/${lang}/emily2.png`,
        `images-large/${lang}/glen1.png`,
        `images-large/${lang}/glen2.png`,
        `images-large/${lang}/jose1.png`,
        `images-large/${lang}/jose2.png`,
        `images-large/${lang}/kamila1.png`,
        `images-large/${lang}/kamila2.png`,
      ]
    },
    updateCanFlipClasses() {
      const flipbook = this.$refs.flipbook
      if (!flipbook) return

      const currentPage = this.pageNum || 1
      const displayedPages = flipbook.displayedPages
      const leftElement = document.querySelector('.click-to-flip.left')
      const rightElement = document.querySelector('.click-to-flip.right')

      const isPage1Visible = currentPage === 1
      const isPage11Visible = currentPage === 11 || (displayedPages === 2 && currentPage === 10)

      if (isPage1Visible) {
        leftElement.classList.remove('canflip')
      } else {
        leftElement.classList.add('canflip')
      }

      if (isPage11Visible) {
        rightElement.classList.remove('canflip')
      } else {
        rightElement.classList.add('canflip')
      }

      // Also override page number labels
      if (displayedPages === 2 && currentPage === 10) {
        //console.log(currentPage);
        //console.log('we are gonna say you are on pages 10 and 11');
        document.querySelector('span.page-num').textContent = 'Pages 10 & 11 of 11';
        //setTimeout(() => {
        //  document.querySelector('span.page-num').textContent = 'Pages 10 & 11 of 11';
        //}, 5);
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', (ev) => {
      const flipbook = this.$refs.flipbook
      if (!flipbook) return
      if (ev.keyCode == 37 && flipbook.canFlipLeft) flipbook.flipLeft()
      if (ev.keyCode == 39 && flipbook.canFlipRight) flipbook.flipRight()
    })

    this.loadPages(this.currentLanguage)

    window.addEventListener('hashchange', this.setPageFromHash)
    this.setPageFromHash()

    // Call updateCanFlipClasses on page load
    this.updateCanFlipClasses()
  },
}
</script>

<style>
/* 
bupa blue #0079c8 
bupa beige #f1efeb
white
black
*/


html, body {
  margin: 0;
  padding: 0;
}

#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;

  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1efeb;
  color: #ccc;
  overflow: hidden;
}

a {
  color: inherit;
}

.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.action-bar .btn {
  font-size: 30px;
  color: #999;
  color: #0079c8;
}

.action-bar .btn svg {
  bottom: 0;
}

.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
  color: #0079c8;
  /*filter: drop-shadow(1px 1px 5px #000);*/
  cursor: pointer;
}

.action-bar .btn:active {
  filter: none !important;
}

.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}

.action-bar .page-num {
  font-size: 16px;
  margin-left: 10px;
  color: #0079c8;
}

.flipbook .viewport {
  width: 95vw !important;
  height: calc(100vh - 50px - 20px) !important;
}

.flipbook .bounding-box {
  /*box-shadow: 0 0 20px #000;*/
  box-shadow: 0 0 10px #b7b6b3;
}

.language-switcher {
  margin-left: 1em;
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  padding: 0.5em;
}
select, option {

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: 1.3em;
}

.click-to-flip {
  background-position: 0 50%;
  /*background-size: contain;*/
  background-repeat: no-repeat;
}
.click-to-flip.left.canflip {
  background-image: url('@/assets/left-icon.png');
}
.click-to-flip.right.canflip {
  background-image: url('@/assets/right-icon.png');
  background-position: right 50%;
}
/*.click-to-flip[style*="cursor: auto"] {
  background-image: none;
}*/
/* make this more performant */

#language {
  display: none;
}

</style>
